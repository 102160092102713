.numberReservation {
    width: 867px;
    min-height: 507px;
    background: white;
    border-radius: 10px;
    padding-bottom: 20px;

    .sliderAndInfo {
        display: flex;
        gap: 14px;

        .info {
            padding-top: 17px;

            .title {
                color: #032E5C;
                font-size: 36px;
                font-weight: 600;
                font-family: Montserrat;
                margin-bottom: 6px;
                text-transform: uppercase;
            }

            .text {
                font-family: Montserrat;
                font-weight: 400;
                color: #032E5C;
                max-width: 464px;
                margin-top: 18px;
                font-size: 11px;
            }

            .humansCapacity {
                font-family: Montserrat;
                color: #032E5C;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 16px;
            }

            .furniture {
                display: flex;
                flex-wrap: wrap;
                gap: 7px;
            }
        }
    }

    .reservation {
        width: 826px;
        padding-top: 15px;
        padding-left: 30px;

        .priceWrapper {
            display: flex;
            align-self: center;
            justify-content: space-between;

            .priceText {
                font-weight: 400;
                font-size: 30px;
                font-family: Montserrat;
                color: #032E5C;
            }

            .price {
                color: #032E5C;
                font-family: Montserrat;
                font-size: 30px;
                font-weight: 700;
            }
        }

        .completion {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;

            .conditionsAndQuests {
                display: flex;
                gap: 30px;

                .cancelAndBank {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 6px;

                    .cancelBtn {
                        color: #032E5C;
                        font-weight: 400;
                        font-size: 16px;
                        font-family: Montserrat;
                        text-decoration: underline;
                    }

                    .bankMapBtn {
                        color: #032E5C;
                        font-weight: 400;
                        font-size: 16px;
                        font-family: Montserrat;
                    }
                }
            }

            .chooses {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .chooseWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .counts {
                        font-size: 16px;
                        color: #032E5C;
                    }

                    .chooseBtn {
                        width: 158px;
                        height: 50px;
                        border-radius: 3px;
                        background: #98BDE2;
                        color: white;
                        font-weight: 400;
                        font-size: 24px;
                        font-family: Montserrat;
                    }
                }
            }

        }
    }
}