.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
        max-width: 725px;
        width: 100%;
        text-align: center;
        color: #032E5C;
        font-size: 55px;
        font-weight: 700;
        font-family: Montserrat;
    }

    .quantities {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 25px;
    }
}

@media (max-width: 500px) {
    .container {
        .head {
            font-size: 32px;
        }
    }
}