.guestInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 10px;
    //width: 1152px;
    max-width: 1152px;
    width: 100%;
    min-height: 118px;
    margin-top: 30px;
    padding: 25px 35px;

    .guestInfoTitle {
        margin-bottom: 28px;
        color: #032E5C;
        font-size: 32px;
        font-weight: 500;
        font-family: Montserrat;

        .span {
            font-size: 20px;

            @media (max-width: 420px) {
                display: inline-block;
            }
        }
    }

    .numberInfo {
        color: #032E5C;
    }

    .inputsData {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        max-width: 1000px;
        margin-top: 20px;

        .input {
            height: 40px;
            width: 280px;
            border: 2px solid #98bde2;
            border-radius: 3px;
            padding-left: 10px;
        }
    }

    .nextGuestBtn {
        display: flex;
        align-items: center;
        gap: 5px;
        align-self: flex-start;
        margin-top: 20px;
        color: #032E5C;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;

        .plus {
            font-size: 30px;
            font-weight: 800;
        }
    }
}

.another {
    padding: 28px 35px;
}

@media (max-width: 680px) {
    .guestInfo {
        .guestInfoTitle {
            text-align: center;
            font-size: 18px;
        }
        .numberInfo {
            text-align: center;
        }
        .inputsData {
            justify-content: center;
        }
    }
}

