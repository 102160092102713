.reservationResult {
    min-width: 270px;
    min-height: 200px;
    height: 100%;
    background: white;
    border-radius: 10px;
    padding-bottom: 20px;

    .head {
        margin-top: 20px;
        margin-bottom: 9px;
        text-align: center;
        line-height: normal;
        color: #032E5C;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
    }

    .line {
        margin-top: 20px;
        width: 100%;
        height: 0.5px;
        background: #AAAAAA;
    }

    .dates {
        .monthDays {
            margin-top: 20px;
            margin-bottom: 9px;
            text-align: center;
            line-height: normal;
            color: #032E5C;
            font-family: Montserrat;
            font-size: 22px;
            font-weight: 400;
        }

        .weekDays {
            display: flex;
            justify-content: space-around;

            .weekDay {
                margin-bottom: 9px;
                text-align: center;
                line-height: normal;
                color: #032E5C;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-top: 20px;
        //margin-left: 30px;
        //margin-right: 30px;

        .typeAndMainSum{
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 17px;

            .type {
                line-height: normal;
                color: #032E5C;
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
            }

            .mainSum {
                line-height: normal;
                color: #032E5C;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
            }
        }

        .extraSums {
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 20px;
            margin-top: 5px;

            .extra {
                line-height: normal;
                color: #032E5C;
                text-align: center;
                font-family: Montserrat;
                font-size: 11px;
                font-weight: 600;
            }

            .extraSum {
                line-height: normal;
                color: #032E5C;
                font-family: Montserrat;
                font-size: 10px;
                font-weight: 400;
            }
        }
    }

    .resultSum {
        margin-top: 20px;
        text-align: center;
        line-height: normal;
        color: #032E5C;
        font-family: Montserrat;
        font-size: 26px;
        font-weight: 700;
    }

    .continueBtn {
        margin-top: 15px;
        margin-left: 30px;
        color: white;
        font-size: 24px;
        font-weight: 600;
        font-family: Montserrat;
        width: 207px;
        height: 50px;
        background: #98bde2;
        border-radius: 5px;
    }
}