.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
    min-height: 623px;
    margin: 0 auto;
    padding-top: 50px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        margin-top: 33px;

        .rests {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 25px;
            min-height: 220px;
            padding-bottom: 70px;
            padding-left: 20px;
            padding-right: 20px;

            .restWrapper {
                position: relative;
                width: 220px;
                max-height: 220px;

                //.restBackground {
                //    position: absolute;
                //    top: 0;
                //    left: 0;
                //    width: 100%;
                //    height: 100%;
                //    background: #98bde2;
                //    border-radius: 19px;
                //}

                .restTitle {
                    position: absolute;
                    z-index: 2;
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: white;
                    text-align: center;
                    line-height: 25px
                }

                .restImg {
                    position: relative;
                    max-width: 220px;
                    max-height: 220px;
                    transition: all 1s;
                }

                &:hover {
                    .restImg {
                        transform: scale(1.05);
                    }
                }

                &:nth-child(odd) {
                    margin-bottom: 50px;
                }

                &:nth-child(even) {
                    margin-top: 50px;
                }
            }

            .moreRestsActive {

                &:nth-child(even) {
                    margin-top: 0;
                    transition: 1s;
                }

                &:nth-child(odd) {
                    margin-bottom: 0;
                    transition: 1s;
                }
            }
        }

        .moreRestsBtn {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: -45px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 2px;
            color: #032E5C;

            .arrow {
                padding-top: 2px;
                width: 15px;
                transform:rotate(90deg);
            }
        }

        .hide {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: -45px;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 2px;
            color: #032E5C;

            .arrow {
                padding-top: 2px;
                width: 15px;
                transform:rotate(-90deg);
            }
        }
    }
}

@media (max-width: 700px) {
    .container {
        padding-top: 0;
        .content {
            margin-top: 0;
            padding-bottom: 50px;
        }
    }
}
