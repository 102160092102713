.extra {
    display: flex;
    //gap: 12px;
    margin-top: 12px;
    position: relative;
    //min-width: 464px;
    //width: 100%;
    z-index: 2;

    .extraElement {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 226px;
        min-height: 63px;
        border: 1px solid #98BDE2;
        background: white;
        border-radius: 3px;
        font-weight: 600;
        font-size: 16px;
        color: #032E5C;
        font-family: Montserrat;
        line-height: 18px;
        text-align: center;

        .extraTitle {
            font-size: 16px;
        }

        .extraCountWrapper {
            margin-top: 10px;
            //overflow: auto;
            .numbers {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .number {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 22px;
                }
            }

            .countChanges {
                width: 81px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                border: 1px solid #98BDE2;

                .minus {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #98BDE2;
                    border-radius: 3px;
                    color: white;
                    height: 20px;
                    width: 20px;
                    font-size: 23px;
                    padding-bottom: 3px;
                }

                .plus {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #98BDE2;
                    border-radius: 3px;
                    color: white;
                    height: 20px;
                    width: 20px;
                    font-size: 22px;
                }
            }
        }
    }

    .mattresses {
        left: 0;
    }

    .nutrition {
        right: 40px;
    }

    .active {
        min-height: 118px;
        padding: 10px 0;
    }
}

@media (max-width: 1281px) {
    .extra {
        margin-top: 10px;
        //margin-bottom: 80px;

        .extraElement {
            width: 170px;

            .extraTitle {
                font-size: 10px;
            }
        }

        .mattresses {
            font-size: 10px;
        }

        .nutrition {
            right: 0;
            font-size: 10px;
        }
    }
}