.container {
    .info {
        .titleAndFurniturs {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                color: #032E5C;
                font-size: 36px;
                font-weight: 600;
                font-family: Montserrat;
                margin-bottom: 6px;
                text-transform: uppercase;
            }

            .furnitureUnactiveBtn {
                .activeFurniture {
                    width: 35px;
                    height: 35px;
                }
            }

            .furnitureActiveBtn {
                transform: rotate(180deg);

                .activeFurniture {
                    width: 35px;
                    height: 35px;
                }
            }
        }
        .furniture {
            display: flex;
            flex-wrap: wrap;
            gap: 7px;
            max-width: 360px;
        }

        .humansCapacity {
            font-family: Montserrat;
            color: #032E5C;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        .priceText {
            font-weight: 400;
            font-size: 20px;
            font-family: Montserrat;
            color: #032E5C;
            margin-top: 80px;
        }

        .priceAndTwoButtons {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cancelAndBank {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .cancel {
                    color: #032E5C;
                    font-weight: 400;
                    font-size: 10px;
                    font-family: Montserrat;
                    text-decoration: underline;
                }

                .bank {
                    color: #032E5C;
                    font-weight: 400;
                    font-size: 10px;
                    font-family: Montserrat;
                }
            }

            .price {
                color: #032E5C;
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 700;
            }

        }

        .chooses {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .chooseWrapper {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;

                .counts {
                    font-size: 10px;
                    color: #032E5C;
                }

                .chooseBtn {
                    width: 100px;
                    height: 30px;
                    border-radius: 3px;
                    background: #98BDE2;
                    color: white;
                    font-weight: 400;
                    font-size: 13px;
                    font-family: Montserrat;
                }
            }
        }
    }
}