.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 1270px;
    width: 100%;

    .newsTitle {
        color: #032E5C;
        font-family: Montserrat;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 40px;
    }

    .news {
        display: flex;
        gap: 30px;
        align-items: flex-start;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;

        .bigNew {
        }

        .smallNews {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }

    .allNewsBtn {
        align-self: flex-end;
        border-radius: 7px;
        border: 1px solid #5E5E5E;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 200px;
        height: 40px;
        margin-top: 34px;
        margin-right: 19px;
    }
}

@media (max-width: 1260px) {
    .container {
        padding-bottom: 40px;
        
        .news {
            flex-direction: column;
        }
    }
}

@media (max-width: 640px) {
    
}