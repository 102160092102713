.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 768px;

    .videoBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 85px;
        width: 100%;

        .infoAndRangePicker {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 628px;

            .mainTitle {
                color: #FFF;
                font-family: Montserrat;
                font-size: 96px;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 14.4px;
                z-index: 1;
                margin-bottom: 42px;
            }

            .mainText {
                z-index: 1;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: white;
                max-width: 594px;
                margin-bottom: 61px;
            }

            .rangeDatePicker {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 5px;
                grid-row-gap: 0px;
                position: relative;
                z-index: 1;

                .linkToReserv {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 70px;
                    height: 40px;
                }

                .searchAndSelect {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr;
                    grid-column-gap: 5px;
                    grid-row-gap: 0px;

                    .searchBtn {
                        width: 70px;
                        height: 40px;
                        border-radius: 4px;
                        color: white;
                        background: #597D9C;
                    }
                }
            }
        }
    }
}

@media (max-width: 1250px) {
    .container {
        padding-top: 80px;
        .content {
            flex-direction: column;
        }
    }
}

@media (max-width: 720px) {
    .container {
        .content {
            padding-left: 20px;
            padding-right: 20px;
            gap: 40px;
            .infoAndRangePicker {
                .mainTitle {
                    font-size: 70px;
                }

                .mainText {
                    font-size: 16px;
                }

                .rangeDatePicker {
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-column-gap: 5px;
                    grid-row-gap: 15px;

                    .searchAndSelect {
                        .searchBtn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .container {
        min-height: 568px;
        max-height: 568px;
        .content {
            padding-bottom: 40px;
            
            .infoAndRangePicker {
                .mainTitle {
                    font-size: 46px;
                    font-weight: 500;
                }

                .mainText {
                    font-size: 16px;
                }

                .rangeDatePicker {
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-column-gap: 5px;
                    grid-row-gap: 15px;

                    .searchAndSelect {
                        .searchBtn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .container {
        .content {
            .infoAndRangePicker {
                .mainTitle {
                    font-size: 40px;
                }

               .rangeDatePicker {
                   max-width: 250px;
                   display: flex;
                   flex-direction: column;
                   align-items: center;
                   justify-content: center;

                   .searchAndSelect {
                       display: flex;
                       flex-direction: column;
                       gap: 15px;

                       .searchBtn {
                           line-height: 15px;
                       }
                   }
               }
            }
        }
    }
}

@media (max-width: 380px) {
    .container {
        .content {
            .infoAndRangePicker {
                .rangeDatePicker {
                    .mainTitle {
                        font-size: 10px;
                    }

                    .searchAndSelect {
                        .searchBtn {
                            line-height: 15px;
                        }
                    }
                }
            }
        }
    }
}