.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 80px;
    left: 0;
    transform: translate(-130%);
    transition: 0.4s;
    z-index: 50;

    & .blur {
        width: 100vw;
        height: 100vh;
        left: 30%;
        backdrop-filter: blur(2px);
        position: absolute;
    }

    & .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 60px;
        position: relative;
        width: 30%;
        height: 100%;
        background: white;
        //img: circleMenu.png

        & .listAndLanguages {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;

            & .routerList {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 70px;
                list-style-type: none;
                padding-left: 40px;
                text-transform: uppercase;
                padding-top: 15px;
                margin-top: 50px;
                z-index: 1;

                li {
                    font-weight: 700;
                    font-size: 16px;
                    color: #032E5C;

                    &:hover {
                        cursor: pointer;
                        background: #98bde2;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }

            .languages {
                display: flex;
                gap: 10px;
                margin-top: 60px;
                padding-right: 20px;

                .language {
                    color: #B6B6B6;
                    //font-family: Poppins;
                    font-size: 16px;
                    font-weight: 700;
                }

                .active {
                    color: #032E5C;
                    border-radius: 12px;
                    background: rgba(196, 196, 196, 0.15);
                    padding: 0px 8px;
                }
            }
        }

        & .order {
            align-self: center;
            width: 261px;
            height: 50px;
            border: 1px solid #032E5C;
            border-radius: 10px;
            color: white;
            background: #032E5C;
            cursor: pointer;
            z-index: 1;

            //&:hover {
            //    background: #032E5C;
            //    color: #191C26;
            //}
        }

        img {
            position: absolute;
            top: 20px;
            right: 0;
            z-index: 0;
        }
    }
}

.active {
    transform: translate(0);
}

@media (max-width: 949px) {
    .menu {
        & .content {
            width: 50%;
        }
    }

    .active {
        & .blur {
            left: 50%;
        }
    }
}

@media (max-width: 750px) {
    .menu {
        & .content {
            width: 100%;
        }

        & .blur {
            backdrop-filter: none;
        }
    }
}