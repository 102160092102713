.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: -60px;
    bottom: 50px;
    width: 311px;
    min-height: 241px;
    max-height: 300px;
    background: white;
    border-radius: 5px;
    border: 1px solid #98bde2;

    .content {
        margin-top: 30px;
        max-width: 248px;
        max-height: 210px;
        overflow: auto;

        .numbersDataHead {
            align-self: flex-start;
            font-size: 18px;
            font-weight: 600;
            font-family: Montserrat;
            color: #032E5C;
        }

        .numbersData {
            display: flex;
            flex-direction: column;
            //gap: 20px;
            //overflow: auto;
        }

        .numberLine {
            margin-top: 20px;
        }

        .saveAndAddNumber {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;

            .addBtn {
                width: 139px;
                font-size: 12px;
                color: #98bde2;
                border: 1px solid #98bde2;
                border-radius: 3px;
                padding: 5px 10px;
            }

            .done {
                width: 87px;
                color: white;
                background: #98bde2;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 3px;
            }
        }
    }
}