.container {
    display: flex;
    width: 100vw;
    height: 60px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;

    .modalWindow {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.7);
        z-index: 51;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        width: 100%;
        background: white;

        .basketImg {
            width: 28px;
            height: 25px;
        }

        .content {
            .date {
                margin-bottom: -8px;

            }
        }
    }

    .right {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #98bde2;

        .continueBtn {
            color: white;
        }
    }
}