.container {
    max-width: 600px;
    width: 100%;
    max-height: 530px;
    height: 100%;
    position: relative;

    .background {
        width: 100%;
        height: auto;
    }

    .content {
        max-width: 5npm 00px;
        position: absolute;
        top: 10%;
        left: 8%;
        bottom: 10%;
        width: 100%;
        height: 100%;

        .head {
            width: 161px;
            background: #98BDE2;
            text-align: center;
            color: white;
            font-size: 30px;
        }

        .title {
            margin-top: 7%;
            max-width: 481px;
            width: 100%;
            font-size: 30px;
            color: white;
        }

        .date {
            margin-top: 15px;
            color: white;
            font-size: 16px;
        }
    }
}

.small {
    max-width: 570px;
    width: 100%;
    position: relative;

    //.background {
    //    width: 100%;
    //    height: 250px;
    //    overflow: hidden;
    //}

    .content {
        .title {
            margin-top: 7%;
            font-size: 20px;
        }
    }
}

@media (max-width: 705px) {
    .container {
        .content {
            width: 300px;
            //.head
            .title {
                font-size: 25px;
            }
        }
    }
}

@media (max-width: 540px) {
    .container {
        .content {
            //.head
            .title {
                max-width: 350px;
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 450px) {
    .container {
        .content {
            //.head
            .head {
                width: 110px;
                font-size: 20px;
            }

            .title {
                max-width: 300px;
                font-size: 16px;
            }

            .date {
                margin-top: 5px;
            }
        }
    }
}

//.container {
//    position: relative;
//    max-width: 600px;
//    width: 100%;
//    max-height: 530px;
//    height: 100%;
//    overflow: hidden;
//    border-radius: 17px;
//
//    .background {
//        position: absolute;
//        top: 0;
//        right: 0;
//        width: 100%;
//        max-height: 530px;
//        height: 100%;
//        transition: all 1s;
//    }
//
//    &:hover {
//        .background {
//            transform: scale(1.05);
//        }
//    }
//
//    .content {
//        position: relative;
//        display: flex;
//        flex-direction: column;
//        align-items: flex-start;
//        padding: 60px 50px;
//
//        .head {
//            width: 161px;
//            background: #98BDE2;
//            text-align: center;
//            color: white;
//            font-size: 30px;
//        }
//
//        .title {
//            margin-top: 229px;
//            //position: absolute;
//            //bottom: 30px;
//            max-width: 481px;
//            width: 100%;
//            font-size: 30px;
//            color: white
//        }
//
//        .date {
//            text-align: center;
//            margin-top: 15px;
//            color: white;
//            font-size: 16px;
//        }
//    }
//}
//
//.small {
//    position: relative;
//    height: 250px;
//    margin-bottom: 0;
//
//    .background {
//        max-height: 250px;
//        max-width: 570px;
//        width: 100%;
//        border-radius: 10px;
//    }
//
//    .content {
//        padding: 30px 50px;
//
//        .head {
//            display: flex;
//            justify-content: center;
//            align-items: center;
//            font-size: 20px;
//            width: 113px;
//            height: 30px;
//        }
//
//        .title {
//            margin-top: 75px;
//            font-size: 20px;
//            line-height: normal;
//        }
//
//        .date {
//            margin-top: 10px;
//        }
//    }
//
//}