.container {
    .roomTypesHead {}

    .roomTypesList {
        display: flex;
        gap: 10px;
    }
}

.container {
    padding: 20px;
    /* добавляем отступы */
}

.roomTypesHead {
    font-size: 1.5rem;
    /* Увеличиваем заголовок */
    margin-bottom: 10px;
    /* Отступ снизу */
}

.roomTypesList {
    display: flex;
    /* Располагаем кнопки в строку */
}

.button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    /* Убираем рамку */
    cursor: pointer;
    background-color: white;
    /* Цвет фона кнопки */
    transition: font-weight 0.3s ease, font-size 0.3s ease;
    /* Плавный переход */
}

.button:hover {
    background-color: #f0f0f0;
    /* Цвет фона при наведении */
}

.selected {
    font-weight: bold;
    /* Жирный текст для выделенной кнопки */
    font-size: 1.1rem;
    /* Увеличенный размер шрифта для выделенной кнопки */
    background-color: lightgray;
    /* Цвет фона для выделенной кнопки */
    color: black;
    /* Цвет текста для выделенной кнопки */
}