.container {
    position: relative;
    width: 100%;
    max-height: 320px;
    background: url("../../../../../public/assets/home/footer/footer.png") center center;
    -webkit-background-size: cover;
    background-size: cover;
    margin-top: 50px;

    .wrapper {
        position: relative;
        max-width: 1270px;
        width: 100%;
        min-height: 320px;
        margin: 0 auto;

        .content {
            display: flex;
            align-items: flex-start;
            gap: 119px;
            position: absolute;
            //left: 85px;
            bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;

            .connection {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 100px;

                .socialMedias {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    margin-top: -33px;
                    margin-bottom: 10px;

                    .socialMedia {
                        width: 32px;
                        height: 32px;
                    }
                }


                .numbers {
                    display: flex;
                    flex-direction: column;
                    margin-top: 17px;
                    .number {
                        color: #FFF;
                        font-family: Montserrat;
                        font-size: 14px;
                        line-height: 15px;
                        font-weight: 600;
                    }
                }

                .adress {
                    color: white;
                    max-width: 200px;
                    text-align: center;
                    margin-top: 15px;
                }
            }

            .navigation {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                color: white;
                padding-top: 20px;

                .head {
                    margin-bottom: 20px;
                    font-size: 22px;
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    text-decoration: none;

                    .listElement {
                        font-weight: 600;
                    }
                }
            }

            .numbers {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 37px;
                .number {
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 14px;
                    line-height: 15px;
                    font-weight: 600;
                }
            }
        }
    }
}

.marginTop {
    margin-top: -200px;
}

@media (max-width: 950px) {
    .marginTop {
        margin-top: 0px;
    }
}

@media (max-width: 890px) {
    .container {
        .wrapper {
            .content {
                gap: 100px;

                .connection {
                    margin-right: 30px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .container {
        .wrapper {
            .content {
                gap: 50px;

                .connection {
                    margin-right: 20px;
                }
            }
        }
    }
}

@media (max-width: 440px) {
    .container {
        .wrapper {
            .content {
                gap: 20px;

                .connection {
                    margin-right: 10px;
                    margin-left: -35px;
                }
            }
        }
    }
}